// extracted by mini-css-extract-plugin
export var root = "PlasmicChangeInfo-module--root--3sV4E";
export var freeBox__qE2Na = "PlasmicChangeInfo-module--freeBox__qE2Na--3jNIM";
export var freeBox__fZb1H = "PlasmicChangeInfo-module--freeBox__fZb1H--3NJKk";
export var header = "PlasmicChangeInfo-module--header--2Qifn";
export var freeBox___7I3LE = "PlasmicChangeInfo-module--freeBox___7I3LE--1erX-";
export var freeBox___90K1L = "PlasmicChangeInfo-module--freeBox___90K1L--2ZLCF";
export var img = "PlasmicChangeInfo-module--img--3PgTA";
export var __wab_imgSpacer = "PlasmicChangeInfo-module--__wab_img-spacer--1W-yo";
export var text__cWu4H = "PlasmicChangeInfo-module--text__cWu4H--2k0xU";
export var freeBox__nk6Ar = "PlasmicChangeInfo-module--freeBox__nk6Ar--3yvK4";
export var text__dluS = "PlasmicChangeInfo-module--text__dluS--1JkSQ";
export var text__k2Nfq = "PlasmicChangeInfo-module--text__k2Nfq--3lNU2";
export var freeBox__qGajn = "PlasmicChangeInfo-module--freeBox__qGajn--37d3y";
export var changeMemberInfo = "PlasmicChangeInfo-module--changeMemberInfo--1QdwP";
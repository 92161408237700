// extracted by mini-css-extract-plugin
export var root = "PlasmicChangeMemberInfo-module--root--26HS6";
export var root__successful = "PlasmicChangeMemberInfo-module--root__successful--2UJcb";
export var 更新密碼 = "PlasmicChangeMemberInfo-module--更新密碼--18zVo";
export var freeBox__wftbz = "PlasmicChangeMemberInfo-module--freeBox__wftbz--3eRt2";
export var freeBox__yX47 = "PlasmicChangeMemberInfo-module--freeBox__yX47--1XWE9";
export var freeBox__successful__yX47Lor9T = "PlasmicChangeMemberInfo-module--freeBox__successful__yX47Lor9T--3Y6eK";
export var text__y139Q = "PlasmicChangeMemberInfo-module--text__y139Q--335hc";
export var 密碼 = "PlasmicChangeMemberInfo-module--密碼--7wyi1";
export var 密碼__successful = "PlasmicChangeMemberInfo-module--密碼__successful--3FtMY";
export var freeBox___9HXnp = "PlasmicChangeMemberInfo-module--freeBox___9HXnp--3OntR";
export var text__sz0RN = "PlasmicChangeMemberInfo-module--text__sz0RN--3rXRP";
export var freeBox__kb7Iv = "PlasmicChangeMemberInfo-module--freeBox__kb7Iv--3vvpm";
export var passWordChangeAlert = "PlasmicChangeMemberInfo-module--passWordChangeAlert--280Qr";
export var passWordChange = "PlasmicChangeMemberInfo-module--passWordChange--3Pd46";
export var 更新資料 = "PlasmicChangeMemberInfo-module--更新資料--3g5gy";
export var freeBox__tbpPu = "PlasmicChangeMemberInfo-module--freeBox__tbpPu--f4Axn";
export var freeBox__wnaas = "PlasmicChangeMemberInfo-module--freeBox__wnaas--2Cauv";
export var freeBox__successful__wnaasLor9T = "PlasmicChangeMemberInfo-module--freeBox__successful__wnaasLor9T--1EEB9";
export var text__cZgaa = "PlasmicChangeMemberInfo-module--text__cZgaa--2vWAc";
export var 收件人 = "PlasmicChangeMemberInfo-module--收件人--1qdWc";
export var 收件人__successful = "PlasmicChangeMemberInfo-module--收件人__successful--1Yl_6";
export var freeBox___1I3Xi = "PlasmicChangeMemberInfo-module--freeBox___1I3Xi--1udmo";
export var text__bc8R9 = "PlasmicChangeMemberInfo-module--text__bc8R9--2ZMkk";
export var chName = "PlasmicChangeMemberInfo-module--chName--3d0Np";
export var text__skjhv = "PlasmicChangeMemberInfo-module--text__skjhv--J6sFP";
export var enName = "PlasmicChangeMemberInfo-module--enName--1efaO";
export var 聯絡電話 = "PlasmicChangeMemberInfo-module--聯絡電話--2VeWa";
export var 聯絡電話__successful = "PlasmicChangeMemberInfo-module--聯絡電話__successful--4WE8M";
export var freeBox__svEcb = "PlasmicChangeMemberInfo-module--freeBox__svEcb--c5fVC";
export var text__uAb4 = "PlasmicChangeMemberInfo-module--text__uAb4--3D9Ec";
export var mobilePhone = "PlasmicChangeMemberInfo-module--mobilePhone--2lGX6";
export var 快遞地址 = "PlasmicChangeMemberInfo-module--快遞地址--1fRad";
export var 快遞地址__successful = "PlasmicChangeMemberInfo-module--快遞地址__successful--3bBWG";
export var freeBox__vgLnO = "PlasmicChangeMemberInfo-module--freeBox__vgLnO--2yCM6";
export var text__x8EVa = "PlasmicChangeMemberInfo-module--text__x8EVa--1jvr8";
export var address = "PlasmicChangeMemberInfo-module--address--2Jpsh";
export var 郵遞區號 = "PlasmicChangeMemberInfo-module--郵遞區號--3MTQb";
export var 郵遞區號__successful = "PlasmicChangeMemberInfo-module--郵遞區號__successful--3U3vo";
export var freeBox__eeRhi = "PlasmicChangeMemberInfo-module--freeBox__eeRhi--3oJ50";
export var text__kge7Q = "PlasmicChangeMemberInfo-module--text__kge7Q--3LO6w";
export var zipCode = "PlasmicChangeMemberInfo-module--zipCode--1h4PX";
export var 申請新帳戶 = "PlasmicChangeMemberInfo-module--申請新帳戶--1Betw";
export var 申請新帳戶__successful = "PlasmicChangeMemberInfo-module--申請新帳戶__successful--3F4-1";
export var changeAccountInfoAlert = "PlasmicChangeMemberInfo-module--changeAccountInfoAlert--2m3g3";
export var changeAccountInfoAlert__successful = "PlasmicChangeMemberInfo-module--changeAccountInfoAlert__successful--tNw6Q";
export var changeAccountInfo = "PlasmicChangeMemberInfo-module--changeAccountInfo--2vuOx";
import React, { useState , useEffect ,useRef} from 'react';
import { PlasmicChangeInfo } from "../components/plasmic/gopanda/PlasmicChangeInfo";
import { Helmet } from "react-helmet"

function ChangeInfo() {
 


  return(
    <>
         <Helmet>
                <title>更改會員資料</title>
         </Helmet>

         <PlasmicChangeInfo/>
    </>
  ) 
  
}

export default ChangeInfo;

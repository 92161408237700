import React, { useState , useEffect ,useRef} from 'react';
import { PlasmicChangeMemberInfo } from "./plasmic/gopanda/PlasmicChangeMemberInfo";
import firebase,{database} from './firebase'
//import firebase from "gatsby-plugin-firebase"
import { Modal,Loader} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

function ChangeMemberInfo_(props, ref) {
   //做一個整體的state
   const [allValues, setAllValues] = useState({

    passWord: '',
    passWordAlert:'',
    accountEmail:'',
    chName:'',
    enName:'',
    mobilePhone:'',
    phoneNumberCheckStatus:'',
    zipCode:'',
    address:'',

  });

 
  
  //一般改內容改state用這個
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
    //console.log(allValues)
  }

  //const database=firebase.database()
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
             console.log(user)
             database.child('/users/'+user.uid).on('value', (snapshot) => {
               
               if(snapshot.val()!=null){
                    let userInfo=snapshot.val()
                    //console.log(userInfo)
                    let nation=userInfo.nation
                    let mobilePhone=userInfo.mobilePhone
                    //因為電話有設定國碼，所以我要處理一下
                    if(nation==='HK'){
                            //mobilePhone='+852'+ mobilePhone
                            mobilePhone=mobilePhone.slice(4,) 
                    }else if(allValues.nation==='MO'){
                            //mobilePhone='+853'+ mobilePhone
                            mobilePhone=mobilePhone.slice(4,) 
                    }else if(allValues.nation==='SG'){
                            //mobilePhone='+65'+ mobilePhone
                            mobilePhone=mobilePhone.slice(3,) 
                    }else if(allValues.nation==='MY'){
                            //mobilePhone='+60'+ mobilePhone
                            mobilePhone=mobilePhone.slice(3,) 
                    }else if(allValues.nation==='TW'){
                          mobilePhone='+886'+ mobilePhone.slice(1,)
                    }

                     setAllValues({ ...allValues, 
                                    uid:user.uid,
                                    userInfo:userInfo,
                                    accountEmail:userInfo.email,
                                    chName:userInfo.chName,
                                    enName:userInfo.enName,
                                    nation:userInfo.nation,
                                    zipCode:userInfo.zipCode,
                                    address:userInfo.address1,
                                    mobilePhone:mobilePhone
                                  })
               }
             });

                 
      }  
           console.log(allValues)

 })

  },[]);

  




  const passWordChangeHandler=()=>{
          if(allValues.accountEmail!==''){
            var actionCodeSettings = {
              // After email is verified, the user will be give the ability to go back
              // to the sign-in page.
              url: 'https://gopanda.tw/',
              handleCodeInApp: false
            };
            firebase.auth().sendPasswordResetEmail(allValues.accountEmail,actionCodeSettings)
            .then(() => {
                      setAllValues({ ...allValues, 
                                    passWordChangeAlert: '請到你的信箱開信重設密碼囉!',
                                  })
            })
            .catch((error) => {
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  //console.log(errorCode)
                  if(errorCode==='auth/user-not-found'){
                        setAllValues({ ...allValues, 
                          loginCheckAlert: '這個帳號沒有註冊紀錄喔!',
                          accountEmail:'',
                          passWord:''
                        })
                  }
            });

          }else{
                  setAllValues({ ...allValues, 
                    loginCheckAlert: "請先填上帳號，再按一次'忘記密碼'",
                    accountEmail:'',
                    passWord:''
                  })
          }

  }

  const recaptcha= useRef(0); 
  //發送驗證碼  
  const mobilePhoneCheckHandler= (e) => {
      setAllValues({ ...allValues, 
                    open: true,
                  })
      let mobilePhone=allValues.mobilePhone
      
      //電話要加上國碼，才有辦法發簡訊
      //這個步驟我們也會把這支加上國碼的電話號碼鎖定，因為他是被驗證過的，我就可以確認電話沒問題
      if(allValues.nation==='HK'){
              mobilePhone='+852'+ mobilePhone
      }else if(allValues.nation==='MO'){
              mobilePhone='+853'+ mobilePhone
      }else if(allValues.nation==='SG'){
              mobilePhone='+65'+ mobilePhone
      }else if(allValues.nation==='MY'){
            mobilePhone='+60'+ mobilePhone
      }else if(allValues.nation==='TW'){
            mobilePhone='+886'+ mobilePhone.slice(1,)
      }
      
      //console.log(mobilePhone)
      
      const appVerifier= new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
      });
  
      firebase.auth().signInWithPhoneNumber(mobilePhone, appVerifier)
          .then((confirmationResult) => {
            setAllValues({ ...allValues, 
                          ['mobilePhoneCheckAlert']: '驗證碼已經送出',
                          ['confirmationResult']:  confirmationResult,
                          ['confirmedmobilePhone']: mobilePhone,
                          open:false,
                        })
            //下面這段是要把recaptcha清乾淨
            recaptcha.current.innerHTML = `<div id="recaptcha-container"></div>`     
          }).catch((error) => {
            setAllValues({ ...allValues, 
                          ['mobilePhoneCheckAlert']: '請確認國家別和電話無誤，再驗證一次，或透過右下方的訊息聯絡我們!',
                        })
            //console.log(error)
          });
    }

  //確認驗證碼
  const sendCheckNumberHandler= (e) => {
      setAllValues({ ...allValues, 
        open: true,
      })
      let phoneCheckNumber=allValues.phoneCheckNumber
      //根據firebase，我需要剛剛回傳的confirmationResult做驗證
      const confirmationResult = allValues.confirmationResult
    
      // confirmationResult.confirm(phoneCheckNumber).then((result) => {
      //           setAllValues({ ...allValues, 
      //                          ['sendCheckNumberAlert']: '手機驗證成功',
      //                          ['phoneNumberCheckStatus']: true,
      //                          open:false,
      //           })
      //           //我不要手機登錄
      //           const user = firebase.auth().currentUser;
      //           user.delete().then(() => {
      //             // User deleted.
      //           }).catch((error) => {
      //             // An error ocurred
      //             // ...
      //           });

      //           //這裡我只是要驗證電話，所以我不要讓user signin
      //           //firebase.auth().signOut()
      //           console.log(user)

                
      // }).catch((error) => {
      //           console.log(error)
      //           setAllValues({ ...allValues, 
      //             ['sendCheckNumberAlert']: '手機驗證失敗',
      //           })
      // });

    }

  const changeAccountInfoHandler=()=>{
    let userInfo=allValues.userInfo
    userInfo['chName']=allValues.chName
    userInfo['enName']=allValues.enName
    userInfo['mobilePhone']=allValues.mobilePhone
    userInfo['address1']=allValues.address
    userInfo['zipCode']=allValues.zipCode

    database.child('/users/'+allValues.uid).set(userInfo)
    .then(()=>{
            setAllValues({ ...allValues, 
                            changeAccountInfoAlert:'資料更新完成!'
                         })


    })
  }

  if(allValues.accountEmail !=''){
    return(
      <>
           <PlasmicChangeMemberInfo 
                root={{ ref }} {...props} 

               
                passWordChange={{
                  onClick:passWordChangeHandler
                }}

                passWordChangeAlert={{
                  children: allValues.passWordChangeAlert 
                }}

                chName={{
                  name:"chName",
                  value: allValues.chName ,
                  onChange: changeHandler,
                }}
                enName={{
                  name:"enName",
                  value: allValues.enName ,
                  onChange: changeHandler,
                }}

                 mobilePhone={{
                  name:"mobilePhone",
                  value: allValues.mobilePhone ,
                  onChange: changeHandler,
                }}

                zipCode={{
                  name:"zipCode",
                  value: allValues.zipCode ,
                  onChange: changeHandler,
                }}
  
                address={{
                  name:"address",
                  value: allValues.address ,
                  onChange: changeHandler,
                }}

                changeAccountInfoAlert={{
                  children: allValues.changeAccountInfoAlert 
                }}

                changeAccountInfo={{
                  onClick:changeAccountInfoHandler
                }}

                
            />
           <Modal
                  basic
                  open={allValues.open}
                  size='small'
                  dimmer='inverted'
            >
                <Loader />   
            </Modal>
      </>
    )  
            
  }else{
    return <></>
  }

  
}

const ChangeMemberInfo = React.forwardRef(ChangeMemberInfo_);

export default ChangeMemberInfo;
